body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media(max-width:767px){
	.markPortrait{
		margin-left:auto;
		margin-right:auto;
		text-align:center;
	}
	.resume-document-thumbnail{
		width:330px;
	}
	.slick-slide img{
		width:85%;
	}
  .ui.vertical.segment.about-me-segment{
    padding:0 !important;
  }
  .ui.vertical.segment.resume-segment{
    padding:0 !important;
  }
}
.ui.vertical.segment.hero-segment{
  border-bottom: 0.66em solid darkslategrey;
}
div.skill-logo {
    display: inline-block;
    margin: 0.66em;
}
span.skill-logo-img {
    display: block;
}
.ui.button>.icon:not(.button) {
	margin:0!important;
}
a.header-social {
    margin: 0.33em;
    font-size:22px;
    color: #1c1d1e;
}
a.header-social:hover{
	opacity:0.75;
}
.slick-slide img {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
body {
  margin: 0;
  padding: 0;
  /* more styles */
}
.particles {
  position: fixed;
  right: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
#particles canvas{
    position: absolute;
    width: 100%;
    height: 450px;
    z-index: -1;
}
.ui.primary.button {
    position: inherit;
    z-index: 99;
}
.ui.secondary.inverted.pointing.menu {
    position: relative;
    z-index: 100;
}
.ui.segment {
    z-index: 99;
    position: relative;
}
.ui.vertical.segment {
    z-index: 99;
    position:inherit;
}
.slick-slider {
    z-index: 99;
}
.slick-dots li.slick-active button{
	color:#fff;
}
.slick-dots li button{
	color:#fff;
}
.slick-dots li.slick-active button:before{
	color:#fff;
}
.slick-dots li button:before{
	color:#fff;
}
.ui.text.container {
    position: relative;
    z-index: 99;
}
.ui.inverted.link.list {
    z-index: 99;
    position: relative;
}
.email-footer{
	position: relative;
	z-index: 99;
}
.column {
    z-index: 99;
}
@media(max-width:767px){
	.particles {
    	position: relative;
	}
}
.button a{
	color:#fff;
}
.button a:hover{
	color:#fff;
	text-decoration:none;
}
.ui.container.stackable.middle.aligned.grid {
    padding: 1.33em;
    background-color: darkslategrey;
}
.skill-logo-img img:hover {
    transform: scale(1.1);
    opacity: 0.9;
}
.skill-logo-img img {
    transition: transform .2s;
}
h3.ui.header.about-text{
	color:#fff;
}
/*.ui.inverted.divided.grid:not([class*="vertically divided"])>.column:not(.row), .ui.inverted.divided.grid:not([class*="vertically divided"])>.row>.column {
    -webkit-box-shadow: -1px 0 0 0 rgba(47, 79, 79, 0.57);
    box-shadow: -1px 0 0 0 rgba(47, 79, 79, 0.57);
}*/
.ui.menu a:hover{
	opacity:0.9;
	text-decoration:underline;
}
.ui.menu.fixed{
  background: rgba(255, 255, 255, 0.85);
}
.ui.menu.fixed a{
  color: #242739;
}
.ui.button.resume-dl-button{
	margin:1.66em;
}

div.App {
    -webkit-animation: fadein 1.33s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1.33s; /* Firefox < 16 */
        -ms-animation: fadein 1.33s; /* Internet Explorer */
         -o-animation: fadein 1.33s; /* Opera < 12.1 */
            animation: fadein 1.33s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
.skill-logo a{
  color: #242739;
  font-weight: 600;
}
.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.margin-top-1{
  margin-top:1em !important;
}
.margin-bottom-1{
  margin-bottom:1em !important;
}
.margin-top-2{
  margin-top:2em !important;
}
.margin-bottom-2{
  margin-bottom:2em !important;
}
.margin-top-3{
  margin-top:3em !important;
}
.margin-bottom-3{
  margin-bottom:3em !important;
}


.padding-top-1{
  padding-top:1em !important;
}
.padding-bottom-1{
  padding-bottom:1em !important;
}
.padding-top-2{
  padding-top:2em !important;
}
.padding-bottom-2{
  padding-bottom:2em !important;
}
.padding-top-3{
  padding-top:3em !important;
}
.padding-bottom-3{
  padding-bottom:3em !important;
}

.nowrap{
  white-space: nowrap;
}